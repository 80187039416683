import React from "react"
import { Navbar, Row, Container } from "react-bootstrap"
import { Location } from "@reach/router"
import SelectLanguage from "./select-language"
import { useIntl, Link } from "gatsby-plugin-intl"

const CtrlNavbar = (): JSX.Element => {
  const intl = useIntl()

  const onScroll = () => {
    const navHeader = document.getElementById("nav-header")
    const collapse = document.getElementById("nav_collapse")
    if (collapse.scrollTop > 50) {
      navHeader.classList.remove("d-flex")
      navHeader.classList.add("d-none")
    } else {
      navHeader.classList.remove("d-none")
      navHeader.classList.add("d-flex")
    }
  }

  return (
    <Row noGutters className="bg-white nav-container">
      <Location>
        {({ location }) => (
          <Container>
            <Navbar expand="lg" className="py-3">
              <div className="d-flex justify-content-between" id="nav-header">
                <div id="logo-wrapper">
                  <Link to="/">
                    <img
                      src="/images/logo-nav.svg"
                      width="154"
                      alt=""
                    />
                  </Link>
                </div>
                <Navbar.Toggle aria-controls="nav_collapse" />
              </div>
              <Navbar.Collapse id="nav_collapse" onScroll={onScroll}>
                <nav
                  role="navigation"
                  className="d-flex justify-content-center align-items-lg-center align-items-start flex-lg-row flex-column mt-lg-0 mt-5"
                >
                  <Link
                    to="/media-agencies/"
                    className="navbar-link mr-4 mb-lg-0 mb-4"
                    activeClassName={
                      location.pathname === "/media-agencies/" ? "active" : null
                    }
                  >
                    {intl.formatMessage({ id: 'navbar.institutions' })}
                  </Link>
                  <Link
                    to="/metodologia/"
                    className="navbar-link mr-4 mb-lg-0 mb-4"
                    activeClassName={
                      location.pathname === "/metodologia/" ? "active" : null
                    }
                  >
                    {intl.formatMessage({ id: 'navbar.metodologia' })}

                  </Link>
                  <Link
                    to="/about/"
                    className="navbar-link mr-4 mb-lg-0 mb-4"
                    activeClassName={
                      location.pathname === "/about/" ? "active" : null
                    }
                  >
                    {intl.formatMessage({ id: 'navbar.about' })}

                  </Link>
                  <Link
                    to="/contact/"
                    className="navbar-link mb-lg-0 mb-4"
                    activeClassName={
                      location.pathname === "/contact/" ? "active" : null
                    }
                  >
                    {intl.formatMessage({ id: 'navbar.contact' })}

                  </Link>
                </nav>
                <div className="align-items-lg-end align-items-start justify-content-lg-end justify-content-start d-flex">
                  <SelectLanguage
                    className="basic-single_header"
                  />
                </div>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        )}
      </Location>
    </Row>
  )
}
export default CtrlNavbar
