import { changeLocale, useIntl } from "gatsby-plugin-intl"
import React from 'react'
import Select from 'react-select'
const DropdownIndicator = () => <img style={{ padding: '15px' }} src="/images/triangle.svg" />

const options = [
  { value: 'ro', label: 'Ro' },
  { value: 'ru', label: 'Ru' },
]

const SelectLanguage = ({ className }: { className: string }): JSX.Element => {
  const intl = useIntl()

  const handleClick = (e) => {
    changeLocale(e.value)
  }

  return (
    <Select
      className={className}
      classNamePrefix="select"
      defaultValue={options[0]}
      isDisabled={false}
      isLoading={false}
      isClearable={false}
      isSearchable={false}
      options={options}
      onChange={handleClick}
      value={options.find(option => option.value === intl.locale)}
      components={{ DropdownIndicator }}
    />
  )
}

export default SelectLanguage
